import { named, withDependencies } from '@wix/thunderbolt-ioc'
import {
	CssFetcherSymbol,
	CssSiteAssetsParams,
	DomReadySymbol,
	Experiments,
	ExperimentsSymbol,
	HeadContentSymbol,
	ICssFetcher,
	IHeadContent,
	ILogger,
	IPageResourceFetcher,
	LoggerSymbol,
	PageResourceFetcherSymbol,
	SiteFeatureConfigSymbol,
	ViewerModel,
	ViewerModelSym,
} from '@wix/thunderbolt-symbols'
import { LocalClientCssFetcher } from './LocalClientPageStyleLoader'
import { AssetsLoaderSiteConfig } from './types'
import { name } from './symbols'

// compCssMappers has to be the last feature to run

const featuresToIgnoreList = ['stylableCss', 'compCssMappers']
const getFeaturesToRunInIsolation = (requestUrl: string, isStylableComponentInStructure: boolean) => {
	const featuresFromUrl = new URL(requestUrl).searchParams.get('cssFeaturesToRun')?.split(',')
	if (featuresFromUrl) {
		return featuresFromUrl
	}
	return featuresToIgnoreList.filter((feature) => feature !== 'stylableCss' || isStylableComponentInStructure)
}

const fetchCssInParallel = (
	featuresToRunInIsolation: Array<string>,
	fetcher: (params: Partial<CssSiteAssetsParams>) => Promise<{ css: string }>
) => {
	return Promise.all([
		fetcher({ featuresToIgnore: featuresToRunInIsolation.join(',') }).then((result) => {
			return {
				css: result.css,
				id: 'css',
			}
		}),
		...featuresToRunInIsolation.map((feature) =>
			fetcher({ featuresToRun: feature }).then((result) => ({
				css: result.css,
				id: feature,
			}))
		),
	])
}

export type ILoadPageStyle = {
	load(pageId: string): Promise<void>
}

export const PageMainCssFetcher = withDependencies<ICssFetcher>(
	[PageResourceFetcherSymbol],
	(pageResourceFetcher: IPageResourceFetcher) => ({
		id: 'css',
		fetch: (pageId, extraModuleParams) => pageResourceFetcher.fetchResource(pageId, 'css', extraModuleParams),
	})
)

export const toDomId = (id: string, pageId: string) => `${id}_${pageId}`

async function getStyles(
	experiments: Experiments,
	pageId: string,
	requestUrl: string,
	cssFetcher: ICssFetcher,
	isStylableComponentInStructure: boolean
) {
	const shouldSplitCssGeneration = experiments['specs.thunderbolt.splitCssRequest'] && pageId !== 'masterPage'
	let styles = []
	if (shouldSplitCssGeneration) {
		const featuresToRunInIsolation = getFeaturesToRunInIsolation(requestUrl, isStylableComponentInStructure)
		styles = await fetchCssInParallel(featuresToRunInIsolation, (config) => cssFetcher.fetch(pageId, config))
	} else {
		const { css } = await cssFetcher.fetch(pageId)
		styles.push({ id: cssFetcher.id, css })
	}
	return styles
}

export const ClientPageStyleLoader = withDependencies<ILoadPageStyle>(
	[
		DomReadySymbol,
		CssFetcherSymbol,
		ViewerModelSym,
		ExperimentsSymbol,
		LoggerSymbol,
		named(SiteFeatureConfigSymbol, name),
	],
	(
		domReadyPromise: Promise<void>,
		cssFetcher: ICssFetcher,
		viewerModel: ViewerModel,
		experiments: Experiments,
		logger: ILogger,
		{ isStylableComponentInStructure }: AssetsLoaderSiteConfig
	) => {
		return {
			async load(pageId): Promise<void> {
				await domReadyPromise
				await logger.runAsyncAndReport(
					async () => {
						if (viewerModel.siteAssets.modulesParams.css.shouldRunCssInBrowser) {
							return LocalClientCssFetcher(cssFetcher, pageId, viewerModel)
						}

						if (document.getElementById(toDomId(cssFetcher.id, pageId))) {
							return
						}

						const styles = await getStyles(
							experiments,
							pageId,
							viewerModel.requestUrl,
							cssFetcher,
							isStylableComponentInStructure
						)
						styles.forEach(({ id, css }) => {
							const styleElement = window.document.createElement('style')
							styleElement.setAttribute('id', toDomId(id, pageId))
							styleElement.innerHTML = css
							if (window.viewerModel.experiments['specs.thunderbolt.pagesCssInHead']) {
								window.document.head.appendChild(styleElement)
							} else {
								window.document.getElementById('pages-css')!.appendChild(styleElement)
							}
						})
					},

					'ClientPageStyleLoader',
					'fetchClientCss'
				)
			},
		}
	}
)

export const ServerPageStyleLoader = withDependencies<ILoadPageStyle>(
	[
		HeadContentSymbol,
		CssFetcherSymbol,
		ExperimentsSymbol,
		LoggerSymbol,
		ViewerModelSym,
		named(SiteFeatureConfigSymbol, name),
	],
	(
		headContent: IHeadContent,
		cssFetcher: ICssFetcher,
		experiments: Experiments,
		logger: ILogger,
		viewerModel: ViewerModel,
		{ isStylableComponentInStructure }: AssetsLoaderSiteConfig
	) => {
		return {
			async load(pageId) {
				await logger.runAsyncAndReport(
					async () => {
						const styles = await getStyles(
							experiments,
							pageId,
							viewerModel.requestUrl,
							cssFetcher,
							isStylableComponentInStructure
						)
						styles.forEach(({ id, css }) => {
							headContent.addPageCss(`<style id="${toDomId(id, pageId)}">${css}</style>`)
						})
					},
					'ServerPageStyleLoader',
					'fetchServerCss'
				)
			},
		}
	}
)
